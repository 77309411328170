import { NodeDescription, QualifiedTypeName } from "../app/shared/syntaxtree";
import { MultiLangString } from "../app/shared/multilingual-string.description";
import { gql } from "apollo-angular";
import { Injectable } from "@angular/core";
import * as Apollo from "apollo-angular";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: string;
  /** Represents untyped JSON */
  JSON: any;
  LangJson: MultiLangString;
  NodeDescription: NodeDescription;
  QualifiedTypeName: QualifiedTypeName;
  SettableDate: any;
};

/** Autogenerated input type of AddMembers */
export type AddMembersInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  projectId: Scalars["ID"];
  userIds: Array<Scalars["ID"]>;
  isAdmin: Scalars["Boolean"];
};

/** Autogenerated return type of AddMembers */
export type AddMembersPayload = {
  __typename?: "AddMembersPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  project?: Maybe<Project>;
};

/** Autogenerated input type of AddUsedBlockLanguage */
export type AddUsedBlockLanguageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  projectId: Scalars["ID"];
  blockLanguageId: Scalars["ID"];
};

/** Autogenerated return type of AddUsedBlockLanguage */
export type AddUsedBlockLanguagePayload = {
  __typename?: "AddUsedBlockLanguagePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  result?: Maybe<Response>;
};

export type AffectedResource = {
  __typename?: "AffectedResource";
  id: Scalars["ID"];
  type: Resource;
};

export type BlockLanguage = {
  __typename?: "BlockLanguage";
  codeResources?: Maybe<Array<CodeResource>>;
  createdAt: Scalars["ISO8601DateTime"];
  defaultProgrammingLanguage: ProgrammingLanguage;
  defaultProgrammingLanguageId: Scalars["ID"];
  editorBlocks: Scalars["JSON"];
  editorComponents: Scalars["JSON"];
  generated?: Maybe<Scalars["Boolean"]>;
  grammar?: Maybe<Grammar>;
  grammarId?: Maybe<Scalars["ID"]>;
  id: Scalars["ID"];
  level?: Maybe<LanguageLevel>;
  localGeneratorInstructions?: Maybe<Scalars["JSON"]>;
  name: Scalars["String"];
  rootCssClasses: Array<Scalars["String"]>;
  sidebars: Scalars["JSON"];
  slug?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for BlockLanguage. */
export type BlockLanguageConnection = {
  __typename?: "BlockLanguageConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BlockLanguageEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BlockLanguage>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type BlockLanguageEdge = {
  __typename?: "BlockLanguageEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<BlockLanguage>;
};

export type BlockLanguageFilterFieldType = {
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  grammar?: Maybe<Scalars["String"]>;
};

export type BlockLanguageInputType = {
  order?: Maybe<BlockLanguageOrderType>;
  filter?: Maybe<BlockLanguageFilterFieldType>;
  languages?: Maybe<Array<LanguageEnum>>;
};

export type BlockLanguageLevelRestrictionType = "whiteList" | "blackList";

export type BlockLanguageOrderFieldEnum = "name" | "slug" | "grammar";

export type BlockLanguageOrderType = {
  orderField?: Maybe<BlockLanguageOrderFieldEnum>;
  orderDirection?: Maybe<OrderDirectionEnum>;
};

/** Autogenerated input type of ChangeMemberRole */
export type ChangeMemberRoleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  projectId: Scalars["ID"];
  userId: Scalars["ID"];
  isAdmin: Scalars["Boolean"];
};

/** Autogenerated return type of ChangeMemberRole */
export type ChangeMemberRolePayload = {
  __typename?: "ChangeMemberRolePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  project?: Maybe<Project>;
};

/** Autogenerated input type of ChangeOwner */
export type ChangeOwnerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  projectId: Scalars["ID"];
  userId: Scalars["ID"];
};

/** Autogenerated return type of ChangeOwner */
export type ChangeOwnerPayload = {
  __typename?: "ChangeOwnerPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  project?: Maybe<Project>;
};

export type CodeResource = {
  __typename?: "CodeResource";
  ast?: Maybe<Scalars["NodeDescription"]>;
  blockLanguage: BlockLanguage;
  blockLanguageId: Scalars["ID"];
  compiled?: Maybe<Scalars["String"]>;
  createdAt: Scalars["ISO8601DateTime"];
  generatedGrammars?: Maybe<Array<Grammar>>;
  id: Scalars["ID"];
  name: Scalars["String"];
  programmingLanguage: ProgrammingLanguage;
  programmingLanguageId: Scalars["ID"];
  project: Project;
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for CodeResource. */
export type CodeResourceConnection = {
  __typename?: "CodeResourceConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CodeResourceEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CodeResource>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** Autogenerated input type of CodeResourceCreate */
export type CodeResourceCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  projectId: Scalars["ID"];
  blockLanguageId: Scalars["ID"];
  programmingLanguageId: Scalars["ID"];
};

/** Autogenerated return type of CodeResourceCreate */
export type CodeResourceCreatePayload = {
  __typename?: "CodeResourceCreatePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  codeResource: CodeResource;
};

/** An edge in a connection. */
export type CodeResourceEdge = {
  __typename?: "CodeResourceEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<CodeResource>;
};

export type CodeResourceFilterFieldType = {
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  programmingLanguageId?: Maybe<Scalars["ID"]>;
};

export type CodeResourceInputType = {
  order?: Maybe<CodeResourceOrderType>;
  filter?: Maybe<CodeResourceFilterFieldType>;
  languages?: Maybe<Array<LanguageEnum>>;
};

export type CodeResourceOrderFieldEnum = "name";

export type CodeResourceOrderType = {
  orderField?: Maybe<CodeResourceOrderFieldEnum>;
  orderDirection?: Maybe<OrderDirectionEnum>;
};

/** Autogenerated input type of CodeResourceUpdate */
export type CodeResourceUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  ast?: Maybe<Scalars["NodeDescription"]>;
  blockLanguageId: Scalars["ID"];
  programmingLanguageId: Scalars["ID"];
};

/** Autogenerated return type of CodeResourceUpdate */
export type CodeResourceUpdatePayload = {
  __typename?: "CodeResourceUpdatePayload";
  affected: Array<UpdateAffectedResource>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  codeResource: CodeResource;
};

/** Autogenerated input type of CreateBlockLanguage */
export type CreateBlockLanguageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  slug?: Maybe<Scalars["String"]>;
  defaultProgrammingLanguageId?: Maybe<Scalars["ID"]>;
  grammarId?: Maybe<Scalars["ID"]>;
  sidebars: Scalars["JSON"];
  editorBlocks: Scalars["JSON"];
  editorComponents: Scalars["JSON"];
  rootCssClasses?: Maybe<Scalars["JSON"]>;
  localGeneratorInstructions?: Maybe<Scalars["JSON"]>;
};

/** Autogenerated return type of CreateBlockLanguage */
export type CreateBlockLanguagePayload = {
  __typename?: "CreateBlockLanguagePayload";
  blockLanguage?: Maybe<BlockLanguage>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
};

/** Autogenerated input type of CreateGrammar */
export type CreateGrammarInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  slug?: Maybe<Scalars["String"]>;
  types: Scalars["JSON"];
  foreignTypes?: Maybe<Scalars["JSON"]>;
  root?: Maybe<Scalars["QualifiedTypeName"]>;
  programmingLanguageId: Scalars["ID"];
  generatedFromId?: Maybe<Scalars["ID"]>;
};

/** Autogenerated return type of CreateGrammar */
export type CreateGrammarPayload = {
  __typename?: "CreateGrammarPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  grammar?: Maybe<Grammar>;
};

/** Autogenerated input type of CreateNews */
export type CreateNewsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  title: Scalars["LangJson"];
  text: Scalars["LangJson"];
  publishedFrom: Scalars["SettableDate"];
};

/** Autogenerated return type of CreateNews */
export type CreateNewsPayload = {
  __typename?: "CreateNewsPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  news?: Maybe<News>;
};

/** Autogenerated input type of CreateProgrammingLanguage */
export type CreateProgrammingLanguageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  projectId: Scalars["ID"];
  languageDisplayName: Scalars["String"];
  languageTechnicalName?: Maybe<Scalars["String"]>;
  runtimeLanguageId: Scalars["String"];
  /** Create an empty code resource with the new language? */
  createInitialCodeResource?: Maybe<Scalars["Boolean"]>;
  /** Split the grammar into two files? */
  createStructureAndSyntaxGrammar?: Maybe<Scalars["Boolean"]>;
  /** Create a block language resource for the block language */
  createMetaBlockLanguage?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of CreateProgrammingLanguage */
export type CreateProgrammingLanguagePayload = {
  __typename?: "CreateProgrammingLanguagePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  createdBlockLanguageCodeResource?: Maybe<CodeResource>;
  createdBlockLanguageUsage: ProjectUsesBlockLanguage;
  initialCodeResource?: Maybe<CodeResource>;
  structureGrammar: Grammar;
  structureGrammarCodeResource: CodeResource;
  syntaxGrammar?: Maybe<Grammar>;
  syntaxGrammarCodeResource?: Maybe<CodeResource>;
};

/** Autogenerated input type of CreateProject */
export type CreateProjectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  name: Scalars["LangJson"];
  slug?: Maybe<Scalars["String"]>;
};

/** Autogenerated return type of CreateProject */
export type CreateProjectPayload = {
  __typename?: "CreateProjectPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
};

export type DateTimeFilter = {
  date?: Maybe<Scalars["ISO8601DateTime"]>;
  until: Scalars["Boolean"];
};

/** Autogenerated input type of DestroyBlockLanguage */
export type DestroyBlockLanguageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of DestroyBlockLanguage */
export type DestroyBlockLanguagePayload = {
  __typename?: "DestroyBlockLanguagePayload";
  blockLanguage?: Maybe<BlockLanguage>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
};

/** Autogenerated input type of DestroyGrammar */
export type DestroyGrammarInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of DestroyGrammar */
export type DestroyGrammarPayload = {
  __typename?: "DestroyGrammarPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  grammar?: Maybe<Grammar>;
};

/** Autogenerated input type of DestroyNews */
export type DestroyNewsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
};

/** Autogenerated return type of DestroyNews */
export type DestroyNewsPayload = {
  __typename?: "DestroyNewsPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  news?: Maybe<News>;
};

/** Autogenerated input type of DestroyProject */
export type DestroyProjectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of DestroyProject */
export type DestroyProjectPayload = {
  __typename?: "DestroyProjectPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
};

export type Grammar = {
  __typename?: "Grammar";
  blockLanguages?: Maybe<Array<BlockLanguage>>;
  codeResources?: Maybe<Array<CodeResource>>;
  createdAt: Scalars["ISO8601DateTime"];
  foreignTypes: Scalars["JSON"];
  foreignVisualisations: Scalars["JSON"];
  generatedFrom?: Maybe<CodeResource>;
  generatedFromId?: Maybe<Scalars["ID"]>;
  id: Scalars["ID"];
  includes?: Maybe<Array<Scalars["ID"]>>;
  name: Scalars["String"];
  programmingLanguage: ProgrammingLanguage;
  programmingLanguageId: Scalars["ID"];
  root?: Maybe<Scalars["QualifiedTypeName"]>;
  slug?: Maybe<Scalars["String"]>;
  types: Scalars["JSON"];
  updatedAt: Scalars["ISO8601DateTime"];
  visualisations: Scalars["JSON"];
  visualizes?: Maybe<Array<Scalars["ID"]>>;
};

/** The connection type for Grammar. */
export type GrammarConnection = {
  __typename?: "GrammarConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GrammarEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Grammar>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type GrammarEdge = {
  __typename?: "GrammarEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<Grammar>;
};

export type GrammarFilterFieldType = {
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  generatedFromId?: Maybe<Scalars["ID"]>;
};

export type GrammarInputType = {
  order?: Maybe<GrammarOrderType>;
  filter?: Maybe<GrammarFilterFieldType>;
  languages?: Maybe<Array<LanguageEnum>>;
};

export type GrammarOrderFieldEnum = "name" | "slug";

export type GrammarOrderType = {
  orderField?: Maybe<GrammarOrderFieldEnum>;
  orderDirection?: Maybe<OrderDirectionEnum>;
};

export type Identity = {
  __typename?: "Identity";
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  ownData?: Maybe<Scalars["JSON"]>;
  provider: Scalars["String"];
  providerData?: Maybe<Scalars["JSON"]>;
  type: Scalars["String"];
  updatedAt: Scalars["ISO8601DateTime"];
  user: User;
};

export type LanguageEnum = "en" | "de";

export type LanguageLevel = {
  __typename?: "LanguageLevel";
  id: BlockLanguageLevelRestrictionType;
  levelTypes: Array<Scalars["QualifiedTypeName"]>;
};

export type LoginProvider = {
  __typename?: "LoginProvider";
  color: Scalars["String"];
  icon: Scalars["String"];
  name: Scalars["String"];
  urlName: Scalars["String"];
};

export type MayPerform = {
  __typename?: "MayPerform";
  perform: Scalars["Boolean"];
};

export type MayPerformInputType = {
  resourceType: Scalars["String"];
  resourceId?: Maybe<Scalars["ID"]>;
  policyAction: Scalars["String"];
};

export type MembershipTypeEnum = "admin" | "participant";

export type Mutation = {
  __typename?: "Mutation";
  addMember?: Maybe<AddMembersPayload>;
  addUsedBlockLanguage?: Maybe<AddUsedBlockLanguagePayload>;
  changeMemberRole?: Maybe<ChangeMemberRolePayload>;
  changeOwner?: Maybe<ChangeOwnerPayload>;
  createBlockLanguage?: Maybe<CreateBlockLanguagePayload>;
  createCodeResource?: Maybe<CodeResourceCreatePayload>;
  createGrammar?: Maybe<CreateGrammarPayload>;
  createNews?: Maybe<CreateNewsPayload>;
  createProgrammingLanguage?: Maybe<CreateProgrammingLanguagePayload>;
  createProject?: Maybe<CreateProjectPayload>;
  destroyBlockLanguage?: Maybe<DestroyBlockLanguagePayload>;
  destroyGrammar?: Maybe<DestroyGrammarPayload>;
  destroyNews?: Maybe<DestroyNewsPayload>;
  destroyProject?: Maybe<DestroyProjectPayload>;
  promoteUserAdmin?: Maybe<PromoteAdminPayload>;
  regenerateForeignTypes?: Maybe<RegenerateForeignTypesPayload>;
  removeMember?: Maybe<RemoveMemberPayload>;
  removeUsedBlockLanguage?: Maybe<RemoveUsedBlockLanguagePayload>;
  storeBlockLanguageSeed?: Maybe<StoreBlockLanguagePayload>;
  storeProjectSeed?: Maybe<StoreProjectPayload>;
  updateBlockLanguage?: Maybe<UpdateBlockLanguagePayload>;
  updateCodeResource?: Maybe<CodeResourceUpdatePayload>;
  updateGrammar?: Maybe<UpdateGrammarPayload>;
  updateNews?: Maybe<UpdateNewsPayload>;
  updateProject?: Maybe<UpdateProjectPayload>;
};

export type MutationAddMemberArgs = {
  input: AddMembersInput;
};

export type MutationAddUsedBlockLanguageArgs = {
  input: AddUsedBlockLanguageInput;
};

export type MutationChangeMemberRoleArgs = {
  input: ChangeMemberRoleInput;
};

export type MutationChangeOwnerArgs = {
  input: ChangeOwnerInput;
};

export type MutationCreateBlockLanguageArgs = {
  input: CreateBlockLanguageInput;
};

export type MutationCreateCodeResourceArgs = {
  input: CodeResourceCreateInput;
};

export type MutationCreateGrammarArgs = {
  input: CreateGrammarInput;
};

export type MutationCreateNewsArgs = {
  input: CreateNewsInput;
};

export type MutationCreateProgrammingLanguageArgs = {
  input: CreateProgrammingLanguageInput;
};

export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
};

export type MutationDestroyBlockLanguageArgs = {
  input: DestroyBlockLanguageInput;
};

export type MutationDestroyGrammarArgs = {
  input: DestroyGrammarInput;
};

export type MutationDestroyNewsArgs = {
  input: DestroyNewsInput;
};

export type MutationDestroyProjectArgs = {
  input: DestroyProjectInput;
};

export type MutationPromoteUserAdminArgs = {
  input: PromoteAdminInput;
};

export type MutationRegenerateForeignTypesArgs = {
  input: RegenerateForeignTypesInput;
};

export type MutationRemoveMemberArgs = {
  input: RemoveMemberInput;
};

export type MutationRemoveUsedBlockLanguageArgs = {
  input: RemoveUsedBlockLanguageInput;
};

export type MutationStoreBlockLanguageSeedArgs = {
  input: StoreBlockLanguageInput;
};

export type MutationStoreProjectSeedArgs = {
  input: StoreProjectInput;
};

export type MutationUpdateBlockLanguageArgs = {
  input: UpdateBlockLanguageInput;
};

export type MutationUpdateCodeResourceArgs = {
  input: CodeResourceUpdateInput;
};

export type MutationUpdateGrammarArgs = {
  input: UpdateGrammarInput;
};

export type MutationUpdateNewsArgs = {
  input: UpdateNewsInput;
};

export type MutationUpdateProjectArgs = {
  input: UpdateProjectInput;
};

export type News = {
  __typename?: "News";
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  publishedFrom: Scalars["SettableDate"];
  renderedTextFull: Scalars["LangJson"];
  renderedTextShort: Scalars["LangJson"];
  text: Scalars["LangJson"];
  title: Scalars["LangJson"];
  updatedAt: Scalars["ISO8601DateTime"];
  user?: Maybe<User>;
  userId?: Maybe<Scalars["ID"]>;
};

/** The connection type for News. */
export type NewsConnection = {
  __typename?: "NewsConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NewsEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<News>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type NewsEdge = {
  __typename?: "NewsEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<News>;
};

export type NewsFilterFieldType = {
  id?: Maybe<Scalars["ID"]>;
  title?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
  publishedFrom?: Maybe<DateTimeFilter>;
};

export type NewsInputType = {
  order?: Maybe<NewsOrderType>;
  filter?: Maybe<NewsFilterFieldType>;
  languages?: Maybe<Array<LanguageEnum>>;
  textLength?: Maybe<TextLengthOptionsEnum>;
};

export type NewsOrderFieldEnum =
  | "title"
  | "publishedFrom"
  | "createdAt"
  | "updatedAt";

export type NewsOrderType = {
  orderField?: Maybe<NewsOrderFieldEnum>;
  orderDirection?: Maybe<OrderDirectionEnum>;
};

export type OrderDirectionEnum = "asc" | "desc";

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

export type ProgrammingLanguage = {
  __typename?: "ProgrammingLanguage";
  id: Scalars["ID"];
  name: Scalars["String"];
};

/** The connection type for ProgrammingLanguage. */
export type ProgrammingLanguageConnection = {
  __typename?: "ProgrammingLanguageConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProgrammingLanguageEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ProgrammingLanguage>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type ProgrammingLanguageEdge = {
  __typename?: "ProgrammingLanguageEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<ProgrammingLanguage>;
};

export type Project = {
  __typename?: "Project";
  blockLanguages: Array<BlockLanguage>;
  codeResourceCount?: Maybe<Scalars["Int"]>;
  codeResources: Array<CodeResource>;
  createdAt: Scalars["ISO8601DateTime"];
  defaultDatabase?: Maybe<ProjectDatabase>;
  defaultDatabaseId?: Maybe<Scalars["ID"]>;
  description: Scalars["LangJson"];
  grammars: Array<Grammar>;
  id: Scalars["ID"];
  indexPageId?: Maybe<Scalars["String"]>;
  members: Array<User>;
  name: Scalars["LangJson"];
  preview?: Maybe<Scalars["String"]>;
  projectMembers: Array<ProjectMember>;
  projectSources: Array<ProjectSource>;
  projectUsesBlockLanguages: Array<ProjectUsesBlockLanguage>;
  public?: Maybe<Scalars["Boolean"]>;
  schema: Array<SqlTable>;
  slug?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["ISO8601DateTime"];
  user?: Maybe<User>;
  userId?: Maybe<Scalars["ID"]>;
};

/** The connection type for Project. */
export type ProjectConnection = {
  __typename?: "ProjectConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProjectEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Project>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type ProjectDatabase = {
  __typename?: "ProjectDatabase";
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  name: Scalars["String"];
  project: Project;
  schema: Array<SqlTable>;
  updatedAt: Scalars["ISO8601DateTime"];
};

/** An edge in a connection. */
export type ProjectEdge = {
  __typename?: "ProjectEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<Project>;
};

export type ProjectFilterFieldType = {
  id?: Maybe<Scalars["ID"]>;
  userId?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  public?: Maybe<Scalars["Boolean"]>;
};

export type ProjectInputType = {
  order?: Maybe<ProjectOrderType>;
  filter?: Maybe<ProjectFilterFieldType>;
  languages?: Maybe<Array<LanguageEnum>>;
};

export type ProjectMember = {
  __typename?: "ProjectMember";
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  joinedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  membershipType: MembershipTypeEnum;
  project: Project;
  projectId: Scalars["ID"];
  updatedAt: Scalars["ISO8601DateTime"];
  user: User;
  userId: Scalars["ID"];
};

export type ProjectOrderFieldEnum = "name" | "slug" | "createdAt";

export type ProjectOrderType = {
  orderField?: Maybe<ProjectOrderFieldEnum>;
  orderDirection?: Maybe<OrderDirectionEnum>;
};

export type ProjectSource = {
  __typename?: "ProjectSource";
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  kind: ProjectSourceKind;
  project: Project;
  readOnly: Scalars["Boolean"];
  title: Scalars["String"];
  updatedAt: Scalars["ISO8601DateTime"];
  url: Scalars["String"];
};

export type ProjectSourceKind = "data";

export type ProjectUsesBlockLanguage = {
  __typename?: "ProjectUsesBlockLanguage";
  blockLanguage: BlockLanguage;
  blockLanguageId: Scalars["ID"];
  id: Scalars["ID"];
  project: Project;
  projectId: Scalars["ID"];
};

/** Autogenerated input type of PromoteAdmin */
export type PromoteAdminInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  userId: Scalars["ID"];
};

/** Autogenerated return type of PromoteAdmin */
export type PromoteAdminPayload = {
  __typename?: "PromoteAdminPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  user: User;
};

export type Query = {
  __typename?: "Query";
  blockLanguage: BlockLanguage;
  blockLanguages: BlockLanguageConnection;
  codeResources: CodeResourceConnection;
  grammar: Grammar;
  grammars: GrammarConnection;
  loginProviders: Array<LoginProvider>;
  mayPerform: MayPerform;
  news: NewsConnection;
  programmingLanguages: ProgrammingLanguageConnection;
  project: Project;
  projects: ProjectConnection;
  relatedBlockLanguages: Array<BlockLanguage>;
  users: UserConnection;
};

export type QueryBlockLanguageArgs = {
  id: Scalars["ID"];
};

export type QueryBlockLanguagesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  input?: Maybe<BlockLanguageInputType>;
};

export type QueryCodeResourcesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  input?: Maybe<CodeResourceInputType>;
};

export type QueryGrammarArgs = {
  id: Scalars["ID"];
};

export type QueryGrammarsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  input?: Maybe<GrammarInputType>;
};

export type QueryMayPerformArgs = {
  input: MayPerformInputType;
};

export type QueryNewsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  input?: Maybe<NewsInputType>;
};

export type QueryProgrammingLanguagesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type QueryProjectArgs = {
  id?: Maybe<Scalars["ID"]>;
};

export type QueryProjectsArgs = {
  input?: Maybe<ProjectInputType>;
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type QueryRelatedBlockLanguagesArgs = {
  grammarId: Scalars["ID"];
};

export type QueryUsersArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  input?: Maybe<UserInputType>;
};

/** Autogenerated input type of RegenerateForeignTypes */
export type RegenerateForeignTypesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of RegenerateForeignTypes */
export type RegenerateForeignTypesPayload = {
  __typename?: "RegenerateForeignTypesPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  grammar: Grammar;
};

/** Autogenerated input type of RemoveMember */
export type RemoveMemberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  projectId: Scalars["ID"];
  userId: Scalars["ID"];
};

/** Autogenerated return type of RemoveMember */
export type RemoveMemberPayload = {
  __typename?: "RemoveMemberPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  project?: Maybe<Project>;
};

/** Autogenerated input type of RemoveUsedBlockLanguage */
export type RemoveUsedBlockLanguageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  usedBlockLanguageId: Scalars["ID"];
};

/** Autogenerated return type of RemoveUsedBlockLanguage */
export type RemoveUsedBlockLanguagePayload = {
  __typename?: "RemoveUsedBlockLanguagePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
};

export type Resource =
  | "ProjectSeed"
  | "ProjectUsesBlockLanguageSeed"
  | "CodeResourceSeed"
  | "BlockLanguageSeed"
  | "GrammarSeed"
  | "GrammarReferenceSeed"
  | "ProgrammingLanguageSeed"
  | "ProjectDatabaseSeed";

export type Response = {
  __typename?: "Response";
  blockLanguage: BlockLanguage;
  projectUsesBlockLanguage: ProjectUsesBlockLanguage;
};

export type Role = {
  __typename?: "Role";
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["ISO8601DateTime"];
  users?: Maybe<Array<User>>;
};

export type SqlColumn = {
  __typename?: "SqlColumn";
  dfltValue?: Maybe<Scalars["String"]>;
  index: Scalars["Int"];
  name: Scalars["String"];
  notNull: Scalars["Boolean"];
  primary: Scalars["Boolean"];
  type: Scalars["String"];
};

export type SqlForeignKey = {
  __typename?: "SqlForeignKey";
  references: Array<SqlForeignKeyReference>;
};

export type SqlForeignKeyReference = {
  __typename?: "SqlForeignKeyReference";
  fromColumn: Scalars["String"];
  toColumn: Scalars["String"];
  toTable: Scalars["String"];
};

export type SqlTable = {
  __typename?: "SqlTable";
  columns: Array<SqlColumn>;
  foreignKeys: Array<SqlForeignKey>;
  name: Scalars["String"];
  systemTable: Scalars["Boolean"];
};

/** Autogenerated input type of StoreBlockLanguage */
export type StoreBlockLanguageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  blockLanguages: Array<Scalars["String"]>;
};

/** Autogenerated return type of StoreBlockLanguage */
export type StoreBlockLanguagePayload = {
  __typename?: "StoreBlockLanguagePayload";
  affectedIds?: Maybe<Array<Array<AffectedResource>>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
};

/** Autogenerated input type of StoreProject */
export type StoreProjectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  projects: Array<Scalars["String"]>;
};

/** Autogenerated return type of StoreProject */
export type StoreProjectPayload = {
  __typename?: "StoreProjectPayload";
  affectedIds?: Maybe<Array<Array<AffectedResource>>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
};

export type TextLengthOptionsEnum = "short" | "long";

export type UpdateAffectedResource = BlockLanguage | Grammar;

/** Autogenerated input type of UpdateBlockLanguage */
export type UpdateBlockLanguageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  defaultProgrammingLanguageId?: Maybe<Scalars["ID"]>;
  grammarId?: Maybe<Scalars["ID"]>;
  sidebars?: Maybe<Scalars["JSON"]>;
  editorBlocks?: Maybe<Scalars["JSON"]>;
  rootCssClasses?: Maybe<Scalars["JSON"]>;
  editorComponents?: Maybe<Scalars["JSON"]>;
  localGeneratorInstructions?: Maybe<Scalars["JSON"]>;
};

/** Autogenerated return type of UpdateBlockLanguage */
export type UpdateBlockLanguagePayload = {
  __typename?: "UpdateBlockLanguagePayload";
  blockLanguage?: Maybe<BlockLanguage>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
};

/** Autogenerated input type of UpdateGrammar */
export type UpdateGrammarInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  types?: Maybe<Scalars["JSON"]>;
  foreignTypes?: Maybe<Scalars["JSON"]>;
  root?: Maybe<Scalars["QualifiedTypeName"]>;
  programmingLanguageId?: Maybe<Scalars["ID"]>;
  generatedFromId?: Maybe<Scalars["ID"]>;
};

/** Autogenerated return type of UpdateGrammar */
export type UpdateGrammarPayload = {
  __typename?: "UpdateGrammarPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  grammar?: Maybe<Grammar>;
};

/** Autogenerated input type of UpdateNews */
export type UpdateNewsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  title?: Maybe<Scalars["LangJson"]>;
  text?: Maybe<Scalars["LangJson"]>;
  publishedFrom: Scalars["SettableDate"];
  userId?: Maybe<Scalars["ID"]>;
};

/** Autogenerated return type of UpdateNews */
export type UpdateNewsPayload = {
  __typename?: "UpdateNewsPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  news?: Maybe<News>;
};

/** Autogenerated input type of UpdateProject */
export type UpdateProjectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["LangJson"]>;
  description?: Maybe<Scalars["LangJson"]>;
  slug?: Maybe<Scalars["String"]>;
  preview?: Maybe<Scalars["ID"]>;
};

/** Autogenerated return type of UpdateProject */
export type UpdateProjectPayload = {
  __typename?: "UpdateProjectPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  project: Project;
};

export type User = {
  __typename?: "User";
  createdAt: Scalars["ISO8601DateTime"];
  displayName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  identities?: Maybe<Array<Identity>>;
  memberAt: Array<Project>;
  news?: Maybe<Array<News>>;
  projectMembers: Array<ProjectMember>;
  projects?: Maybe<Array<Project>>;
  roles: Array<Role>;
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for User. */
export type UserConnection = {
  __typename?: "UserConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<User>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: "UserEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

export type UserFilterFieldType = {
  id?: Maybe<Scalars["ID"]>;
  displayName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
};

export type UserInputType = {
  order?: Maybe<UserOrderType>;
  filter?: Maybe<UserFilterFieldType>;
  languages?: Maybe<Array<LanguageEnum>>;
};

export type UserOrderFieldEnum = "id" | "displayName" | "email";

export type UserOrderType = {
  orderField?: Maybe<UserOrderFieldEnum>;
  orderDirection?: Maybe<OrderDirectionEnum>;
};

export type AdminListBlockLanguagesQueryVariables = Exact<{
  first?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
  input?: Maybe<BlockLanguageInputType>;
}>;

export type AdminListBlockLanguagesQuery = { __typename?: "Query" } & {
  blockLanguages: { __typename?: "BlockLanguageConnection" } & Pick<
    BlockLanguageConnection,
    "totalCount"
  > & {
      nodes?: Maybe<
        Array<
          Maybe<
            { __typename?: "BlockLanguage" } & Pick<
              BlockLanguage,
              "id" | "name" | "slug" | "generated" | "grammarId"
            >
          >
        >
      >;
      pageInfo: { __typename?: "PageInfo" } & Pick<
        PageInfo,
        "hasPreviousPage" | "hasNextPage" | "startCursor" | "endCursor"
      >;
    };
};

export type AdminListGrammarsQueryVariables = Exact<{
  first?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
  input?: Maybe<GrammarInputType>;
}>;

export type AdminListGrammarsQuery = { __typename?: "Query" } & {
  grammars: { __typename?: "GrammarConnection" } & Pick<
    GrammarConnection,
    "totalCount"
  > & {
      nodes?: Maybe<
        Array<
          Maybe<
            { __typename?: "Grammar" } & Pick<
              Grammar,
              "id" | "name" | "slug" | "programmingLanguageId"
            > & {
                generatedFrom?: Maybe<
                  { __typename?: "CodeResource" } & Pick<
                    CodeResource,
                    "id" | "name"
                  > & {
                      project: { __typename?: "Project" } & Pick<
                        Project,
                        "id" | "name"
                      >;
                    }
                >;
              }
          >
        >
      >;
      pageInfo: { __typename?: "PageInfo" } & Pick<
        PageInfo,
        "hasPreviousPage" | "hasNextPage" | "startCursor" | "endCursor"
      >;
    };
};

export type AdminListNewsQueryVariables = Exact<{
  first?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
  input?: Maybe<NewsInputType>;
}>;

export type AdminListNewsQuery = { __typename?: "Query" } & {
  news: { __typename?: "NewsConnection" } & Pick<
    NewsConnection,
    "totalCount"
  > & {
      nodes?: Maybe<
        Array<
          Maybe<
            { __typename?: "News" } & Pick<
              News,
              | "id"
              | "title"
              | "text"
              | "publishedFrom"
              | "createdAt"
              | "updatedAt"
              | "userId"
            >
          >
        >
      >;
      pageInfo: { __typename?: "PageInfo" } & Pick<
        PageInfo,
        "hasPreviousPage" | "hasNextPage" | "startCursor" | "endCursor"
      >;
    };
};

export type AdminListProjectsQueryVariables = Exact<{
  first?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
  input?: Maybe<ProjectInputType>;
}>;

export type AdminListProjectsQuery = { __typename?: "Query" } & {
  projects: { __typename?: "ProjectConnection" } & Pick<
    ProjectConnection,
    "totalCount"
  > & {
      nodes?: Maybe<
        Array<
          Maybe<
            { __typename?: "Project" } & Pick<
              Project,
              "id" | "name" | "slug" | "codeResourceCount" | "createdAt"
            > & {
                user?: Maybe<
                  { __typename?: "User" } & Pick<User, "displayName">
                >;
              }
          >
        >
      >;
      pageInfo: { __typename?: "PageInfo" } & Pick<
        PageInfo,
        "hasPreviousPage" | "hasNextPage" | "startCursor" | "endCursor"
      >;
    };
};

export type AdminListUsersQueryVariables = Exact<{
  first?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
  input?: Maybe<UserInputType>;
}>;

export type AdminListUsersQuery = { __typename?: "Query" } & {
  users: { __typename?: "UserConnection" } & {
    nodes?: Maybe<
      Array<
        Maybe<
          { __typename?: "User" } & Pick<
            User,
            "id" | "displayName" | "email"
          > & {
              roles: Array<{ __typename?: "Role" } & Pick<Role, "id" | "name">>;
            }
        >
      >
    >;
  };
};

export type AdminMetaCodeResourcesQueryVariables = Exact<{
  programmingLanguageId: Scalars["ID"];
}>;

export type AdminMetaCodeResourcesQuery = { __typename?: "Query" } & {
  codeResources: { __typename?: "CodeResourceConnection" } & {
    nodes?: Maybe<
      Array<
        Maybe<
          { __typename?: "CodeResource" } & Pick<
            CodeResource,
            "id" | "name"
          > & {
              project: { __typename?: "Project" } & Pick<
                Project,
                "id" | "name"
              >;
            }
        >
      >
    >;
  };
};

export type AdminRelatedBlockLanguagesQueryVariables = Exact<{
  grammarId: Scalars["ID"];
}>;

export type AdminRelatedBlockLanguagesQuery = { __typename?: "Query" } & {
  relatedBlockLanguages: Array<
    { __typename?: "BlockLanguage" } & Pick<BlockLanguage, "id" | "name">
  >;
};

export type AdminSingleNewsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type AdminSingleNewsQuery = { __typename?: "Query" } & {
  news: { __typename?: "NewsConnection" } & {
    nodes?: Maybe<
      Array<
        Maybe<
          { __typename?: "News" } & Pick<
            News,
            | "id"
            | "title"
            | "text"
            | "publishedFrom"
            | "createdAt"
            | "updatedAt"
            | "userId"
          >
        >
      >
    >;
  };
};

export type CodeResourcesGalleryQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CodeResourcesGalleryQuery = { __typename?: "Query" } & {
  codeResources: { __typename?: "CodeResourceConnection" } & {
    nodes?: Maybe<
      Array<
        Maybe<
          { __typename?: "CodeResource" } & Pick<CodeResource, "id" | "name">
        >
      >
    >;
  };
};

export type CreateBlockLanguageMutationVariables = Exact<{
  name: Scalars["String"];
  slug?: Maybe<Scalars["String"]>;
  defaultProgrammingLanguageId?: Maybe<Scalars["ID"]>;
  grammarId?: Maybe<Scalars["ID"]>;
  sidebars: Scalars["JSON"];
  editorBlocks: Scalars["JSON"];
  editorComponents: Scalars["JSON"];
  rootCssClasses?: Maybe<Scalars["JSON"]>;
  localGeneratorInstructions?: Maybe<Scalars["JSON"]>;
}>;

export type CreateBlockLanguageMutation = { __typename?: "Mutation" } & {
  createBlockLanguage?: Maybe<
    { __typename?: "CreateBlockLanguagePayload" } & Pick<
      CreateBlockLanguagePayload,
      "id" | "errors"
    >
  >;
};

export type CreateCodeResourceMutationVariables = Exact<{
  name: Scalars["String"];
  projectId: Scalars["ID"];
  blockLanguageId: Scalars["ID"];
  programmingLanguageId: Scalars["ID"];
}>;

export type CreateCodeResourceMutation = { __typename?: "Mutation" } & {
  createCodeResource?: Maybe<
    { __typename?: "CodeResourceCreatePayload" } & {
      codeResource: { __typename?: "CodeResource" } & Pick<
        CodeResource,
        "id" | "name" | "blockLanguageId" | "ast" | "programmingLanguageId"
      >;
    }
  >;
};

export type CreateGrammarMutationVariables = Exact<{
  name: Scalars["String"];
  slug?: Maybe<Scalars["String"]>;
  types: Scalars["JSON"];
  foreignTypes?: Maybe<Scalars["JSON"]>;
  root?: Maybe<Scalars["QualifiedTypeName"]>;
  programmingLanguageId: Scalars["ID"];
}>;

export type CreateGrammarMutation = { __typename?: "Mutation" } & {
  createGrammar?: Maybe<
    { __typename?: "CreateGrammarPayload" } & Pick<
      CreateGrammarPayload,
      "errors"
    > & {
        grammar?: Maybe<
          { __typename?: "Grammar" } & Pick<
            Grammar,
            | "id"
            | "name"
            | "programmingLanguageId"
            | "slug"
            | "generatedFromId"
            | "foreignTypes"
            | "root"
            | "types"
            | "visualizes"
            | "includes"
          >
        >;
      }
  >;
};

export type CreateNewsMutationVariables = Exact<{
  title: Scalars["LangJson"];
  text: Scalars["LangJson"];
  publishedFrom: Scalars["SettableDate"];
}>;

export type CreateNewsMutation = { __typename?: "Mutation" } & {
  createNews?: Maybe<
    { __typename?: "CreateNewsPayload" } & Pick<CreateNewsPayload, "errors"> & {
        news?: Maybe<
          { __typename?: "News" } & Pick<
            News,
            | "id"
            | "createdAt"
            | "updatedAt"
            | "publishedFrom"
            | "text"
            | "title"
            | "userId"
          >
        >;
      }
  >;
};

export type CreateProgrammingLanguageMutationVariables = Exact<{
  projectId: Scalars["ID"];
  languageDisplayName: Scalars["String"];
  languageTechnicalName?: Maybe<Scalars["String"]>;
  runtimeLanguageId: Scalars["String"];
  createInitialCodeResource?: Maybe<Scalars["Boolean"]>;
  createStructureAndSyntaxGrammar?: Maybe<Scalars["Boolean"]>;
  createMetaBlockLanguage?: Maybe<Scalars["Boolean"]>;
}>;

export type CreateProgrammingLanguageMutation = { __typename?: "Mutation" } & {
  createProgrammingLanguage?: Maybe<
    { __typename?: "CreateProgrammingLanguagePayload" } & {
      structureGrammar: { __typename?: "Grammar" } & Pick<
        Grammar,
        | "id"
        | "name"
        | "types"
        | "visualisations"
        | "foreignTypes"
        | "foreignVisualisations"
      >;
      structureGrammarCodeResource: { __typename?: "CodeResource" } & Pick<
        CodeResource,
        "id" | "name" | "ast" | "blockLanguageId" | "programmingLanguageId"
      >;
      syntaxGrammar?: Maybe<
        { __typename?: "Grammar" } & Pick<
          Grammar,
          | "id"
          | "name"
          | "types"
          | "visualisations"
          | "foreignTypes"
          | "foreignVisualisations"
        >
      >;
      syntaxGrammarCodeResource?: Maybe<
        { __typename?: "CodeResource" } & Pick<
          CodeResource,
          "id" | "name" | "ast" | "blockLanguageId" | "programmingLanguageId"
        >
      >;
      initialCodeResource?: Maybe<
        { __typename?: "CodeResource" } & Pick<
          CodeResource,
          "id" | "name" | "ast" | "blockLanguageId" | "programmingLanguageId"
        >
      >;
      createdBlockLanguageUsage: {
        __typename?: "ProjectUsesBlockLanguage";
      } & Pick<ProjectUsesBlockLanguage, "id" | "blockLanguageId">;
      createdBlockLanguageCodeResource?: Maybe<
        { __typename?: "CodeResource" } & Pick<
          CodeResource,
          "id" | "name" | "ast" | "blockLanguageId" | "programmingLanguageId"
        >
      >;
    }
  >;
};

export type CreateProjectMutationVariables = Exact<{
  name: Scalars["LangJson"];
  slug?: Maybe<Scalars["String"]>;
}>;

export type CreateProjectMutation = { __typename?: "Mutation" } & {
  createProject?: Maybe<
    { __typename?: "CreateProjectPayload" } & Pick<
      CreateProjectPayload,
      "errors" | "id"
    >
  >;
};

export type DestroyBlockLanguageMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DestroyBlockLanguageMutation = { __typename?: "Mutation" } & {
  destroyBlockLanguage?: Maybe<
    { __typename?: "DestroyBlockLanguagePayload" } & Pick<
      DestroyBlockLanguagePayload,
      "id" | "errors"
    >
  >;
};

export type DestroyGrammarMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DestroyGrammarMutation = { __typename?: "Mutation" } & {
  destroyGrammar?: Maybe<
    { __typename?: "DestroyGrammarPayload" } & Pick<
      DestroyGrammarPayload,
      "errors"
    >
  >;
};

export type DestroyNewsMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DestroyNewsMutation = { __typename?: "Mutation" } & {
  destroyNews?: Maybe<
    { __typename?: "DestroyNewsPayload" } & Pick<
      DestroyNewsPayload,
      "id" | "errors"
    >
  >;
};

export type DestroyProjectMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DestroyProjectMutation = { __typename?: "Mutation" } & {
  destroyProject?: Maybe<
    { __typename?: "DestroyProjectPayload" } & Pick<
      DestroyProjectPayload,
      "errors"
    >
  >;
};

export type FrontpageListNewsQueryVariables = Exact<{
  languages?: Maybe<Array<LanguageEnum> | LanguageEnum>;
}>;

export type FrontpageListNewsQuery = { __typename?: "Query" } & {
  news: { __typename?: "NewsConnection" } & {
    nodes?: Maybe<
      Array<
        Maybe<
          { __typename?: "News" } & Pick<
            News,
            "id" | "title" | "renderedTextShort" | "publishedFrom"
          >
        >
      >
    >;
  };
};

export type FrontpageListProjectsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FrontpageListProjectsQuery = { __typename?: "Query" } & {
  projects: { __typename?: "ProjectConnection" } & {
    nodes?: Maybe<
      Array<
        Maybe<
          { __typename?: "Project" } & Pick<
            Project,
            | "id"
            | "slug"
            | "name"
            | "public"
            | "description"
            | "preview"
            | "indexPageId"
            | "createdAt"
            | "updatedAt"
            | "userId"
          > & {
              blockLanguages: Array<
                { __typename?: "BlockLanguage" } & {
                  defaultProgrammingLanguage: {
                    __typename?: "ProgrammingLanguage";
                  } & Pick<ProgrammingLanguage, "id" | "name">;
                }
              >;
            }
        >
      >
    >;
  };
};

export type FrontpageSingleNewsQueryVariables = Exact<{
  id: Scalars["ID"];
  languages?: Maybe<Array<LanguageEnum> | LanguageEnum>;
}>;

export type FrontpageSingleNewsQuery = { __typename?: "Query" } & {
  news: { __typename?: "NewsConnection" } & {
    nodes?: Maybe<
      Array<
        Maybe<
          { __typename?: "News" } & Pick<
            News,
            "id" | "title" | "renderedTextFull" | "publishedFrom"
          >
        >
      >
    >;
  };
};

export type FullBlockLanguageQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FullBlockLanguageQuery = { __typename?: "Query" } & {
  blockLanguage: { __typename?: "BlockLanguage" } & Pick<
    BlockLanguage,
    | "id"
    | "name"
    | "slug"
    | "generated"
    | "grammarId"
    | "sidebars"
    | "editorBlocks"
    | "editorComponents"
    | "rootCssClasses"
    | "localGeneratorInstructions"
    | "createdAt"
    | "updatedAt"
    | "defaultProgrammingLanguageId"
  >;
};

export type FullGrammarQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FullGrammarQuery = { __typename?: "Query" } & {
  grammar: { __typename?: "Grammar" } & Pick<
    Grammar,
    | "id"
    | "name"
    | "programmingLanguageId"
    | "slug"
    | "generatedFromId"
    | "types"
    | "foreignTypes"
    | "visualisations"
    | "foreignVisualisations"
    | "root"
    | "includes"
    | "visualizes"
  > & {
      blockLanguages?: Maybe<
        Array<
          { __typename?: "BlockLanguage" } & Pick<BlockLanguage, "id" | "name">
        >
      >;
    };
};

export type FullProjectQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FullProjectQuery = { __typename?: "Query" } & {
  project: { __typename?: "Project" } & Pick<
    Project,
    | "id"
    | "slug"
    | "name"
    | "description"
    | "public"
    | "indexPageId"
    | "createdAt"
    | "updatedAt"
  > & {
      projectMembers: Array<
        { __typename?: "ProjectMember" } & Pick<
          ProjectMember,
          "id" | "membershipType" | "joinedAt"
        > & { user: { __typename?: "User" } & Pick<User, "id" | "displayName"> }
      >;
      user?: Maybe<{ __typename?: "User" } & Pick<User, "id" | "displayName">>;
      defaultDatabase?: Maybe<
        { __typename?: "ProjectDatabase" } & Pick<
          ProjectDatabase,
          "id" | "name"
        > & {
            schema: Array<
              { __typename?: "SqlTable" } & Pick<
                SqlTable,
                "name" | "systemTable"
              > & {
                  columns: Array<
                    { __typename?: "SqlColumn" } & Pick<
                      SqlColumn,
                      | "index"
                      | "name"
                      | "type"
                      | "notNull"
                      | "dfltValue"
                      | "primary"
                    >
                  >;
                  foreignKeys: Array<
                    { __typename?: "SqlForeignKey" } & {
                      references: Array<
                        { __typename?: "SqlForeignKeyReference" } & Pick<
                          SqlForeignKeyReference,
                          "fromColumn" | "toTable" | "toColumn"
                        >
                      >;
                    }
                  >;
                }
            >;
          }
      >;
      codeResources: Array<
        { __typename?: "CodeResource" } & Pick<
          CodeResource,
          "id" | "name" | "ast" | "blockLanguageId" | "programmingLanguageId"
        >
      >;
      grammars: Array<
        { __typename?: "Grammar" } & Pick<
          Grammar,
          | "id"
          | "name"
          | "root"
          | "types"
          | "foreignTypes"
          | "visualisations"
          | "foreignVisualisations"
          | "includes"
          | "visualizes"
          | "programmingLanguageId"
          | "createdAt"
          | "updatedAt"
        >
      >;
      blockLanguages: Array<
        { __typename?: "BlockLanguage" } & Pick<
          BlockLanguage,
          | "id"
          | "name"
          | "sidebars"
          | "editorBlocks"
          | "editorComponents"
          | "localGeneratorInstructions"
          | "rootCssClasses"
          | "grammarId"
          | "defaultProgrammingLanguageId"
          | "createdAt"
          | "updatedAt"
        >
      >;
      projectUsesBlockLanguages: Array<
        { __typename?: "ProjectUsesBlockLanguage" } & Pick<
          ProjectUsesBlockLanguage,
          "id" | "blockLanguageId"
        >
      >;
      projectSources: Array<
        { __typename?: "ProjectSource" } & Pick<
          ProjectSource,
          "id" | "kind" | "readOnly" | "title" | "url"
        >
      >;
    };
};

export type GrammarDescriptionItemQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GrammarDescriptionItemQuery = { __typename?: "Query" } & {
  grammars: { __typename?: "GrammarConnection" } & {
    nodes?: Maybe<
      Array<
        Maybe<
          { __typename?: "Grammar" } & Pick<
            Grammar,
            | "id"
            | "name"
            | "programmingLanguageId"
            | "slug"
            | "generatedFromId"
            | "foreignTypes"
            | "root"
            | "types"
            | "visualisations"
            | "foreignVisualisations"
          >
        >
      >
    >;
  };
};

export type GrammarGeneratedByQueryVariables = Exact<{
  codeResourceId?: Maybe<Scalars["ID"]>;
}>;

export type GrammarGeneratedByQuery = { __typename?: "Query" } & {
  grammars: { __typename?: "GrammarConnection" } & {
    nodes?: Maybe<
      Array<Maybe<{ __typename?: "Grammar" } & Pick<Grammar, "id" | "name">>>
    >;
  };
};

export type ListUserProjectsQueryVariables = Exact<{
  userId?: Maybe<Scalars["ID"]>;
  first?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
}>;

export type ListUserProjectsQuery = { __typename?: "Query" } & {
  projects: { __typename?: "ProjectConnection" } & Pick<
    ProjectConnection,
    "totalCount"
  > & {
      nodes?: Maybe<
        Array<
          Maybe<
            { __typename?: "Project" } & Pick<
              Project,
              "id" | "name" | "slug" | "createdAt"
            >
          >
        >
      >;
      pageInfo: { __typename?: "PageInfo" } & Pick<
        PageInfo,
        "hasPreviousPage" | "hasNextPage" | "startCursor" | "endCursor"
      >;
    };
};

export type LoginProvidersQueryVariables = Exact<{ [key: string]: never }>;

export type LoginProvidersQuery = { __typename?: "Query" } & {
  loginProviders: Array<
    { __typename?: "LoginProvider" } & Pick<
      LoginProvider,
      "name" | "color" | "icon" | "urlName"
    >
  >;
};

export type MayPerformQueryVariables = Exact<{
  input: MayPerformInputType;
}>;

export type MayPerformQuery = { __typename?: "Query" } & {
  mayPerform: { __typename?: "MayPerform" } & Pick<MayPerform, "perform">;
};

export type NameBlockLanguageQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type NameBlockLanguageQuery = { __typename?: "Query" } & {
  blockLanguage: { __typename?: "BlockLanguage" } & Pick<
    BlockLanguage,
    "id" | "name"
  >;
};

export type ProjectAddMemberMutationVariables = Exact<{
  projectId: Scalars["ID"];
  userIds: Array<Scalars["ID"]> | Scalars["ID"];
  isAdmin: Scalars["Boolean"];
}>;

export type ProjectAddMemberMutation = { __typename?: "Mutation" } & {
  addMember?: Maybe<
    { __typename?: "AddMembersPayload" } & {
      project?: Maybe<
        { __typename?: "Project" } & Pick<Project, "id"> & {
            projectMembers: Array<
              { __typename?: "ProjectMember" } & Pick<
                ProjectMember,
                "createdAt" | "membershipType" | "joinedAt" | "id"
              > & {
                  user: { __typename?: "User" } & Pick<
                    User,
                    "id" | "displayName"
                  >;
                }
            >;
          }
      >;
    }
  >;
};

export type ProjectAddUsedBlockLanguageMutationVariables = Exact<{
  projectId: Scalars["ID"];
  blockLanguageId: Scalars["ID"];
}>;

export type ProjectAddUsedBlockLanguageMutation = {
  __typename?: "Mutation";
} & {
  addUsedBlockLanguage?: Maybe<
    { __typename?: "AddUsedBlockLanguagePayload" } & {
      result?: Maybe<
        { __typename?: "Response" } & {
          projectUsesBlockLanguage: {
            __typename?: "ProjectUsesBlockLanguage";
          } & Pick<ProjectUsesBlockLanguage, "id">;
        }
      >;
    }
  >;
};

export type ProjectChangeMemberRoleMutationVariables = Exact<{
  projectId: Scalars["ID"];
  userId: Scalars["ID"];
  isAdmin: Scalars["Boolean"];
}>;

export type ProjectChangeMemberRoleMutation = { __typename?: "Mutation" } & {
  changeMemberRole?: Maybe<
    { __typename?: "ChangeMemberRolePayload" } & {
      project?: Maybe<
        { __typename?: "Project" } & Pick<Project, "id"> & {
            projectMembers: Array<
              { __typename?: "ProjectMember" } & Pick<
                ProjectMember,
                "id" | "createdAt" | "membershipType"
              > & {
                  user: { __typename?: "User" } & Pick<
                    User,
                    "id" | "displayName"
                  >;
                }
            >;
          }
      >;
    }
  >;
};

export type ProjectChangeOwnerMutationVariables = Exact<{
  projectId: Scalars["ID"];
  userId: Scalars["ID"];
}>;

export type ProjectChangeOwnerMutation = { __typename?: "Mutation" } & {
  changeOwner?: Maybe<
    { __typename?: "ChangeOwnerPayload" } & {
      project?: Maybe<
        { __typename?: "Project" } & Pick<Project, "id"> & {
            projectMembers: Array<
              { __typename?: "ProjectMember" } & Pick<
                ProjectMember,
                "createdAt" | "membershipType" | "joinedAt" | "id"
              > & {
                  user: { __typename?: "User" } & Pick<
                    User,
                    "id" | "displayName"
                  >;
                }
            >;
            user?: Maybe<
              { __typename?: "User" } & Pick<User, "id" | "displayName">
            >;
          }
      >;
    }
  >;
};

export type ProjectRemoveMemberMutationVariables = Exact<{
  projectId: Scalars["ID"];
  userId: Scalars["ID"];
}>;

export type ProjectRemoveMemberMutation = { __typename?: "Mutation" } & {
  removeMember?: Maybe<
    { __typename?: "RemoveMemberPayload" } & {
      project?: Maybe<
        { __typename?: "Project" } & Pick<Project, "id"> & {
            projectMembers: Array<
              { __typename?: "ProjectMember" } & Pick<
                ProjectMember,
                "createdAt" | "id"
              > & {
                  user: { __typename?: "User" } & Pick<
                    User,
                    "id" | "displayName"
                  >;
                }
            >;
          }
      >;
    }
  >;
};

export type ProjectRemoveUsedBlockLanguageMutationVariables = Exact<{
  usedBlockLanguageId: Scalars["ID"];
}>;

export type ProjectRemoveUsedBlockLanguageMutation = {
  __typename?: "Mutation";
} & {
  removeUsedBlockLanguage?: Maybe<
    { __typename?: "RemoveUsedBlockLanguagePayload" } & Pick<
      RemoveUsedBlockLanguagePayload,
      "errors"
    >
  >;
};

export type PromoteUserAdminMutationVariables = Exact<{
  userId: Scalars["ID"];
}>;

export type PromoteUserAdminMutation = { __typename?: "Mutation" } & {
  promoteUserAdmin?: Maybe<
    { __typename?: "PromoteAdminPayload" } & {
      user: { __typename?: "User" } & Pick<User, "id"> & {
          roles: Array<
            { __typename?: "Role" } & Pick<
              Role,
              "id" | "name" | "updatedAt" | "createdAt"
            >
          >;
        };
    }
  >;
};

export type RegenerateForeignTypesMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RegenerateForeignTypesMutation = { __typename?: "Mutation" } & {
  regenerateForeignTypes?: Maybe<
    { __typename?: "RegenerateForeignTypesPayload" } & {
      grammar: { __typename?: "Grammar" } & Pick<Grammar, "foreignTypes">;
    }
  >;
};

export type SelectionListBlockLanguagesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SelectionListBlockLanguagesQuery = { __typename?: "Query" } & {
  blockLanguages: { __typename?: "BlockLanguageConnection" } & {
    nodes?: Maybe<
      Array<
        Maybe<
          { __typename?: "BlockLanguage" } & Pick<BlockLanguage, "id" | "name">
        >
      >
    >;
  };
};

export type SelectionListGrammarsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SelectionListGrammarsQuery = { __typename?: "Query" } & {
  grammars: { __typename?: "GrammarConnection" } & {
    nodes?: Maybe<
      Array<Maybe<{ __typename?: "Grammar" } & Pick<Grammar, "id" | "name">>>
    >;
  };
};

export type SelectionListGrammarsCreateBlockLanguageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SelectionListGrammarsCreateBlockLanguageQuery = {
  __typename?: "Query";
} & {
  grammars: { __typename?: "GrammarConnection" } & {
    nodes?: Maybe<
      Array<
        Maybe<
          { __typename?: "Grammar" } & Pick<
            Grammar,
            "id" | "name" | "programmingLanguageId"
          >
        >
      >
    >;
  };
};

export type StoreBlockLanguageSeedMutationVariables = Exact<{
  blockLanguageIds: Array<Scalars["String"]> | Scalars["String"];
}>;

export type StoreBlockLanguageSeedMutation = { __typename?: "Mutation" } & {
  storeBlockLanguageSeed?: Maybe<
    { __typename?: "StoreBlockLanguagePayload" } & {
      affectedIds?: Maybe<
        Array<
          Array<
            { __typename?: "AffectedResource" } & Pick<
              AffectedResource,
              "id" | "type"
            >
          >
        >
      >;
    }
  >;
};

export type StoreProjectSeedMutationVariables = Exact<{
  projectIds: Array<Scalars["String"]> | Scalars["String"];
}>;

export type StoreProjectSeedMutation = { __typename?: "Mutation" } & {
  storeProjectSeed?: Maybe<
    { __typename?: "StoreProjectPayload" } & {
      affectedIds?: Maybe<
        Array<
          Array<
            { __typename?: "AffectedResource" } & Pick<
              AffectedResource,
              "id" | "type"
            >
          >
        >
      >;
    }
  >;
};

export type UpdateBlockLanguageMutationVariables = Exact<{
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  defaultProgrammingLanguageId?: Maybe<Scalars["ID"]>;
  grammarId?: Maybe<Scalars["ID"]>;
  sidebars?: Maybe<Scalars["JSON"]>;
  editorBlocks?: Maybe<Scalars["JSON"]>;
  rootCssClasses?: Maybe<Scalars["JSON"]>;
  editorComponents?: Maybe<Scalars["JSON"]>;
  localGeneratorInstructions?: Maybe<Scalars["JSON"]>;
}>;

export type UpdateBlockLanguageMutation = { __typename?: "Mutation" } & {
  updateBlockLanguage?: Maybe<
    { __typename?: "UpdateBlockLanguagePayload" } & Pick<
      UpdateBlockLanguagePayload,
      "id" | "errors"
    >
  >;
};

export type UpdateCodeResourceMutationVariables = Exact<{
  id: Scalars["ID"];
  name: Scalars["String"];
  ast?: Maybe<Scalars["NodeDescription"]>;
  blockLanguageId: Scalars["ID"];
  programmingLanguageId: Scalars["ID"];
}>;

export type UpdateCodeResourceMutation = { __typename?: "Mutation" } & {
  updateCodeResource?: Maybe<
    { __typename?: "CodeResourceUpdatePayload" } & {
      codeResource: { __typename?: "CodeResource" } & Pick<
        CodeResource,
        "name" | "ast" | "blockLanguageId" | "programmingLanguageId"
      >;
      affected: Array<
        | ({ __typename: "BlockLanguage" } & Pick<
            BlockLanguage,
            | "id"
            | "name"
            | "sidebars"
            | "editorBlocks"
            | "editorComponents"
            | "localGeneratorInstructions"
            | "rootCssClasses"
            | "grammarId"
            | "defaultProgrammingLanguageId"
          >)
        | ({ __typename: "Grammar" } & Pick<
            Grammar,
            | "id"
            | "name"
            | "root"
            | "types"
            | "foreignTypes"
            | "visualisations"
            | "foreignVisualisations"
            | "includes"
            | "visualizes"
            | "programmingLanguageId"
          >)
      >;
    }
  >;
};

export type UpdateGrammarMutationVariables = Exact<{
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  types?: Maybe<Scalars["JSON"]>;
  foreignTypes?: Maybe<Scalars["JSON"]>;
  root?: Maybe<Scalars["QualifiedTypeName"]>;
  programmingLanguageId?: Maybe<Scalars["ID"]>;
  generatedFromId?: Maybe<Scalars["ID"]>;
}>;

export type UpdateGrammarMutation = { __typename?: "Mutation" } & {
  updateGrammar?: Maybe<
    { __typename?: "UpdateGrammarPayload" } & Pick<
      UpdateGrammarPayload,
      "errors"
    > & {
        grammar?: Maybe<
          { __typename?: "Grammar" } & Pick<
            Grammar,
            | "id"
            | "name"
            | "programmingLanguageId"
            | "slug"
            | "generatedFromId"
            | "foreignTypes"
            | "root"
            | "types"
            | "includes"
            | "visualizes"
          >
        >;
      }
  >;
};

export type UpdateNewsMutationVariables = Exact<{
  id: Scalars["ID"];
  title: Scalars["LangJson"];
  text: Scalars["LangJson"];
  publishedFrom: Scalars["SettableDate"];
}>;

export type UpdateNewsMutation = { __typename?: "Mutation" } & {
  updateNews?: Maybe<
    { __typename?: "UpdateNewsPayload" } & Pick<UpdateNewsPayload, "errors"> & {
        news?: Maybe<
          { __typename?: "News" } & Pick<
            News,
            | "id"
            | "createdAt"
            | "updatedAt"
            | "publishedFrom"
            | "text"
            | "title"
            | "userId"
          >
        >;
      }
  >;
};

export type UpdateProjectMutationVariables = Exact<{
  id: Scalars["ID"];
  name?: Maybe<Scalars["LangJson"]>;
  description?: Maybe<Scalars["LangJson"]>;
  preview?: Maybe<Scalars["ID"]>;
}>;

export type UpdateProjectMutation = { __typename?: "Mutation" } & {
  updateProject?: Maybe<
    { __typename?: "UpdateProjectPayload" } & Pick<
      UpdateProjectPayload,
      "errors"
    > & {
        project: { __typename?: "Project" } & Pick<
          Project,
          "name" | "description" | "slug" | "preview"
        >;
      }
  >;
};

export const AdminListBlockLanguagesDocument = gql`
  query AdminListBlockLanguages(
    $first: Int
    $after: String
    $before: String
    $last: Int
    $input: BlockLanguageInputType
  ) {
    blockLanguages(
      first: $first
      after: $after
      before: $before
      last: $last
      input: $input
    ) {
      nodes {
        id
        name
        slug
        generated
        grammarId
      }
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class AdminListBlockLanguagesGQL extends Apollo.Query<
  AdminListBlockLanguagesQuery,
  AdminListBlockLanguagesQueryVariables
> {
  document = AdminListBlockLanguagesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AdminListGrammarsDocument = gql`
  query AdminListGrammars(
    $first: Int
    $after: String
    $before: String
    $last: Int
    $input: GrammarInputType
  ) {
    grammars(
      first: $first
      after: $after
      before: $before
      last: $last
      input: $input
    ) {
      nodes {
        id
        name
        slug
        programmingLanguageId
        generatedFrom {
          id
          name
          project {
            id
            name
          }
        }
      }
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class AdminListGrammarsGQL extends Apollo.Query<
  AdminListGrammarsQuery,
  AdminListGrammarsQueryVariables
> {
  document = AdminListGrammarsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AdminListNewsDocument = gql`
  query AdminListNews(
    $first: Int
    $after: String
    $before: String
    $last: Int
    $input: NewsInputType
  ) {
    news(
      first: $first
      after: $after
      before: $before
      last: $last
      input: $input
    ) {
      nodes {
        id
        title
        text
        publishedFrom
        createdAt
        updatedAt
        userId
      }
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class AdminListNewsGQL extends Apollo.Query<
  AdminListNewsQuery,
  AdminListNewsQueryVariables
> {
  document = AdminListNewsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AdminListProjectsDocument = gql`
  query AdminListProjects(
    $first: Int
    $after: String
    $before: String
    $last: Int
    $input: ProjectInputType
  ) {
    projects(
      first: $first
      after: $after
      before: $before
      last: $last
      input: $input
    ) {
      nodes {
        id
        name
        slug
        codeResourceCount
        user {
          displayName
        }
        createdAt
      }
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class AdminListProjectsGQL extends Apollo.Query<
  AdminListProjectsQuery,
  AdminListProjectsQueryVariables
> {
  document = AdminListProjectsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AdminListUsersDocument = gql`
  query AdminListUsers(
    $first: Int
    $after: String
    $before: String
    $last: Int
    $input: UserInputType
  ) {
    users(
      first: $first
      after: $after
      before: $before
      last: $last
      input: $input
    ) {
      nodes {
        id
        displayName
        email
        roles {
          id
          name
        }
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class AdminListUsersGQL extends Apollo.Query<
  AdminListUsersQuery,
  AdminListUsersQueryVariables
> {
  document = AdminListUsersDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AdminMetaCodeResourcesDocument = gql`
  query AdminMetaCodeResources($programmingLanguageId: ID!) {
    codeResources(
      input: { filter: { programmingLanguageId: $programmingLanguageId } }
    ) {
      nodes {
        id
        name
        project {
          id
          name
        }
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class AdminMetaCodeResourcesGQL extends Apollo.Query<
  AdminMetaCodeResourcesQuery,
  AdminMetaCodeResourcesQueryVariables
> {
  document = AdminMetaCodeResourcesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AdminRelatedBlockLanguagesDocument = gql`
  query AdminRelatedBlockLanguages($grammarId: ID!) {
    relatedBlockLanguages(grammarId: $grammarId) {
      id
      name
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class AdminRelatedBlockLanguagesGQL extends Apollo.Query<
  AdminRelatedBlockLanguagesQuery,
  AdminRelatedBlockLanguagesQueryVariables
> {
  document = AdminRelatedBlockLanguagesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AdminSingleNewsDocument = gql`
  query AdminSingleNews($id: ID!) {
    news(input: { filter: { id: $id } }) {
      nodes {
        id
        title
        text
        publishedFrom
        createdAt
        updatedAt
        userId
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class AdminSingleNewsGQL extends Apollo.Query<
  AdminSingleNewsQuery,
  AdminSingleNewsQueryVariables
> {
  document = AdminSingleNewsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CodeResourcesGalleryDocument = gql`
  query CodeResourcesGallery($id: ID!) {
    codeResources {
      nodes {
        id
        name
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class CodeResourcesGalleryGQL extends Apollo.Query<
  CodeResourcesGalleryQuery,
  CodeResourcesGalleryQueryVariables
> {
  document = CodeResourcesGalleryDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateBlockLanguageDocument = gql`
  mutation CreateBlockLanguage(
    $name: String!
    $slug: String
    $defaultProgrammingLanguageId: ID
    $grammarId: ID
    $sidebars: JSON!
    $editorBlocks: JSON!
    $editorComponents: JSON!
    $rootCssClasses: JSON
    $localGeneratorInstructions: JSON
  ) {
    createBlockLanguage(
      input: {
        name: $name
        slug: $slug
        defaultProgrammingLanguageId: $defaultProgrammingLanguageId
        grammarId: $grammarId
        sidebars: $sidebars
        editorBlocks: $editorBlocks
        editorComponents: $editorComponents
        rootCssClasses: $rootCssClasses
        localGeneratorInstructions: $localGeneratorInstructions
      }
    ) {
      id
      errors
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class CreateBlockLanguageGQL extends Apollo.Mutation<
  CreateBlockLanguageMutation,
  CreateBlockLanguageMutationVariables
> {
  document = CreateBlockLanguageDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateCodeResourceDocument = gql`
  mutation CreateCodeResource(
    $name: String!
    $projectId: ID!
    $blockLanguageId: ID!
    $programmingLanguageId: ID!
  ) {
    createCodeResource(
      input: {
        name: $name
        projectId: $projectId
        blockLanguageId: $blockLanguageId
        programmingLanguageId: $programmingLanguageId
      }
    ) {
      codeResource {
        id
        name
        blockLanguageId
        ast
        programmingLanguageId
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class CreateCodeResourceGQL extends Apollo.Mutation<
  CreateCodeResourceMutation,
  CreateCodeResourceMutationVariables
> {
  document = CreateCodeResourceDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateGrammarDocument = gql`
  mutation CreateGrammar(
    $name: String!
    $slug: String
    $types: JSON!
    $foreignTypes: JSON
    $root: QualifiedTypeName
    $programmingLanguageId: ID!
  ) {
    createGrammar(
      input: {
        name: $name
        slug: $slug
        types: $types
        foreignTypes: $foreignTypes
        root: $root
        programmingLanguageId: $programmingLanguageId
      }
    ) {
      grammar {
        id
        name
        programmingLanguageId
        slug
        generatedFromId
        foreignTypes
        root
        types
        visualizes
        includes
      }
      errors
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class CreateGrammarGQL extends Apollo.Mutation<
  CreateGrammarMutation,
  CreateGrammarMutationVariables
> {
  document = CreateGrammarDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateNewsDocument = gql`
  mutation CreateNews(
    $title: LangJson!
    $text: LangJson!
    $publishedFrom: SettableDate!
  ) {
    createNews(
      input: { title: $title, text: $text, publishedFrom: $publishedFrom }
    ) {
      news {
        id
        createdAt
        updatedAt
        publishedFrom
        text
        title
        userId
      }
      errors
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class CreateNewsGQL extends Apollo.Mutation<
  CreateNewsMutation,
  CreateNewsMutationVariables
> {
  document = CreateNewsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateProgrammingLanguageDocument = gql`
  mutation CreateProgrammingLanguage(
    $projectId: ID!
    $languageDisplayName: String!
    $languageTechnicalName: String
    $runtimeLanguageId: String!
    $createInitialCodeResource: Boolean
    $createStructureAndSyntaxGrammar: Boolean
    $createMetaBlockLanguage: Boolean
  ) {
    createProgrammingLanguage(
      input: {
        projectId: $projectId
        languageDisplayName: $languageDisplayName
        languageTechnicalName: $languageTechnicalName
        runtimeLanguageId: $runtimeLanguageId
        createInitialCodeResource: $createInitialCodeResource
        createStructureAndSyntaxGrammar: $createStructureAndSyntaxGrammar
        createMetaBlockLanguage: $createMetaBlockLanguage
      }
    ) {
      structureGrammar {
        id
        name
        types
        visualisations
        foreignTypes
        foreignVisualisations
      }
      structureGrammarCodeResource {
        id
        name
        ast
        blockLanguageId
        programmingLanguageId
      }
      syntaxGrammar {
        id
        name
        types
        visualisations
        foreignTypes
        foreignVisualisations
      }
      syntaxGrammarCodeResource {
        id
        name
        ast
        blockLanguageId
        programmingLanguageId
      }
      initialCodeResource {
        id
        name
        ast
        blockLanguageId
        programmingLanguageId
      }
      createdBlockLanguageUsage {
        id
        blockLanguageId
      }
      createdBlockLanguageCodeResource {
        id
        name
        ast
        blockLanguageId
        programmingLanguageId
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class CreateProgrammingLanguageGQL extends Apollo.Mutation<
  CreateProgrammingLanguageMutation,
  CreateProgrammingLanguageMutationVariables
> {
  document = CreateProgrammingLanguageDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateProjectDocument = gql`
  mutation CreateProject($name: LangJson!, $slug: String) {
    createProject(input: { name: $name, slug: $slug }) {
      errors
      id
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class CreateProjectGQL extends Apollo.Mutation<
  CreateProjectMutation,
  CreateProjectMutationVariables
> {
  document = CreateProjectDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DestroyBlockLanguageDocument = gql`
  mutation DestroyBlockLanguage($id: ID!) {
    destroyBlockLanguage(input: { id: $id }) {
      id
      errors
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class DestroyBlockLanguageGQL extends Apollo.Mutation<
  DestroyBlockLanguageMutation,
  DestroyBlockLanguageMutationVariables
> {
  document = DestroyBlockLanguageDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DestroyGrammarDocument = gql`
  mutation DestroyGrammar($id: ID!) {
    destroyGrammar(input: { id: $id }) {
      errors
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class DestroyGrammarGQL extends Apollo.Mutation<
  DestroyGrammarMutation,
  DestroyGrammarMutationVariables
> {
  document = DestroyGrammarDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DestroyNewsDocument = gql`
  mutation DestroyNews($id: ID!) {
    destroyNews(input: { id: $id }) {
      id
      errors
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class DestroyNewsGQL extends Apollo.Mutation<
  DestroyNewsMutation,
  DestroyNewsMutationVariables
> {
  document = DestroyNewsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DestroyProjectDocument = gql`
  mutation DestroyProject($id: ID!) {
    destroyProject(input: { id: $id }) {
      errors
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class DestroyProjectGQL extends Apollo.Mutation<
  DestroyProjectMutation,
  DestroyProjectMutationVariables
> {
  document = DestroyProjectDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const FrontpageListNewsDocument = gql`
  query FrontpageListNews($languages: [LanguageEnum!]) {
    news(
      first: 5
      input: {
        filter: { publishedFrom: { until: true } }
        textLength: short
        languages: $languages
        order: { orderField: publishedFrom, orderDirection: desc }
      }
    ) {
      nodes {
        id
        title
        renderedTextShort
        publishedFrom
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class FrontpageListNewsGQL extends Apollo.Query<
  FrontpageListNewsQuery,
  FrontpageListNewsQueryVariables
> {
  document = FrontpageListNewsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const FrontpageListProjectsDocument = gql`
  query FrontpageListProjects {
    projects(input: { filter: { public: true } }) {
      nodes {
        id
        slug
        name
        public
        description
        preview
        indexPageId
        createdAt
        updatedAt
        userId
        blockLanguages {
          defaultProgrammingLanguage {
            id
            name
          }
        }
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class FrontpageListProjectsGQL extends Apollo.Query<
  FrontpageListProjectsQuery,
  FrontpageListProjectsQueryVariables
> {
  document = FrontpageListProjectsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const FrontpageSingleNewsDocument = gql`
  query FrontpageSingleNews($id: ID!, $languages: [LanguageEnum!]) {
    news(
      first: 1
      input: {
        filter: { id: $id }
        textLength: short
        languages: $languages
        order: { orderField: publishedFrom, orderDirection: desc }
      }
    ) {
      nodes {
        id
        title
        renderedTextFull
        publishedFrom
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class FrontpageSingleNewsGQL extends Apollo.Query<
  FrontpageSingleNewsQuery,
  FrontpageSingleNewsQueryVariables
> {
  document = FrontpageSingleNewsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const FullBlockLanguageDocument = gql`
  query FullBlockLanguage($id: ID!) {
    blockLanguage(id: $id) {
      id
      name
      slug
      generated
      grammarId
      sidebars
      editorBlocks
      editorComponents
      rootCssClasses
      localGeneratorInstructions
      createdAt
      updatedAt
      defaultProgrammingLanguageId
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class FullBlockLanguageGQL extends Apollo.Query<
  FullBlockLanguageQuery,
  FullBlockLanguageQueryVariables
> {
  document = FullBlockLanguageDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const FullGrammarDocument = gql`
  query FullGrammar($id: ID!) {
    grammar(id: $id) {
      id
      name
      programmingLanguageId
      slug
      generatedFromId
      types
      foreignTypes
      visualisations
      foreignVisualisations
      root
      includes
      visualizes
      blockLanguages {
        id
        name
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class FullGrammarGQL extends Apollo.Query<
  FullGrammarQuery,
  FullGrammarQueryVariables
> {
  document = FullGrammarDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const FullProjectDocument = gql`
  query FullProject($id: ID!) {
    project(id: $id) {
      id
      slug
      name
      description
      public
      indexPageId
      createdAt
      updatedAt
      projectMembers {
        id
        membershipType
        joinedAt
        user {
          id
          displayName
        }
      }
      user {
        id
        displayName
      }
      defaultDatabase {
        id
        name
        schema {
          name
          columns {
            index
            name
            type
            notNull
            dfltValue
            primary
          }
          foreignKeys {
            references {
              fromColumn
              toTable
              toColumn
            }
          }
          systemTable
        }
      }
      codeResources {
        id
        name
        ast
        blockLanguageId
        programmingLanguageId
      }
      grammars {
        id
        name
        root
        types
        foreignTypes
        visualisations
        foreignVisualisations
        includes
        visualizes
        programmingLanguageId
        createdAt
        updatedAt
      }
      blockLanguages {
        id
        name
        sidebars
        editorBlocks
        editorComponents
        localGeneratorInstructions
        rootCssClasses
        grammarId
        defaultProgrammingLanguageId
        createdAt
        updatedAt
      }
      projectUsesBlockLanguages {
        id
        blockLanguageId
      }
      projectSources {
        id
        kind
        readOnly
        title
        url
      }
      defaultDatabase {
        schema {
          name
          columns {
            index
            name
            type
            notNull
            dfltValue
            primary
          }
          foreignKeys {
            references {
              fromColumn
              toTable
              toColumn
            }
          }
          systemTable
        }
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class FullProjectGQL extends Apollo.Query<
  FullProjectQuery,
  FullProjectQueryVariables
> {
  document = FullProjectDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GrammarDescriptionItemDocument = gql`
  query GrammarDescriptionItem($id: ID!) {
    grammars(input: { filter: { id: $id } }) {
      nodes {
        id
        name
        programmingLanguageId
        slug
        generatedFromId
        foreignTypes
        root
        types
        visualisations
        foreignVisualisations
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class GrammarDescriptionItemGQL extends Apollo.Query<
  GrammarDescriptionItemQuery,
  GrammarDescriptionItemQueryVariables
> {
  document = GrammarDescriptionItemDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GrammarGeneratedByDocument = gql`
  query GrammarGeneratedBy($codeResourceId: ID) {
    grammars(input: { filter: { generatedFromId: $codeResourceId } }) {
      nodes {
        id
        name
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class GrammarGeneratedByGQL extends Apollo.Query<
  GrammarGeneratedByQuery,
  GrammarGeneratedByQueryVariables
> {
  document = GrammarGeneratedByDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ListUserProjectsDocument = gql`
  query ListUserProjects(
    $userId: ID
    $first: Int
    $after: String
    $before: String
    $last: Int
  ) {
    projects(
      first: $first
      after: $after
      before: $before
      last: $last
      input: { filter: { userId: $userId } }
    ) {
      nodes {
        id
        name
        slug
        createdAt
      }
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class ListUserProjectsGQL extends Apollo.Query<
  ListUserProjectsQuery,
  ListUserProjectsQueryVariables
> {
  document = ListUserProjectsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const LoginProvidersDocument = gql`
  query LoginProviders {
    loginProviders {
      name
      color
      icon
      urlName
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class LoginProvidersGQL extends Apollo.Query<
  LoginProvidersQuery,
  LoginProvidersQueryVariables
> {
  document = LoginProvidersDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MayPerformDocument = gql`
  query MayPerform($input: MayPerformInputType!) {
    mayPerform(input: $input) {
      perform
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class MayPerformGQL extends Apollo.Query<
  MayPerformQuery,
  MayPerformQueryVariables
> {
  document = MayPerformDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const NameBlockLanguageDocument = gql`
  query NameBlockLanguage($id: ID!) {
    blockLanguage(id: $id) {
      id
      name
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class NameBlockLanguageGQL extends Apollo.Query<
  NameBlockLanguageQuery,
  NameBlockLanguageQueryVariables
> {
  document = NameBlockLanguageDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ProjectAddMemberDocument = gql`
  mutation ProjectAddMember(
    $projectId: ID!
    $userIds: [ID!]!
    $isAdmin: Boolean!
  ) {
    addMember(
      input: { projectId: $projectId, userIds: $userIds, isAdmin: $isAdmin }
    ) {
      project {
        id
        projectMembers {
          createdAt
          membershipType
          joinedAt
          id
          user {
            id
            displayName
          }
        }
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class ProjectAddMemberGQL extends Apollo.Mutation<
  ProjectAddMemberMutation,
  ProjectAddMemberMutationVariables
> {
  document = ProjectAddMemberDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ProjectAddUsedBlockLanguageDocument = gql`
  mutation ProjectAddUsedBlockLanguage($projectId: ID!, $blockLanguageId: ID!) {
    addUsedBlockLanguage(
      input: { projectId: $projectId, blockLanguageId: $blockLanguageId }
    ) {
      result {
        projectUsesBlockLanguage {
          id
        }
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class ProjectAddUsedBlockLanguageGQL extends Apollo.Mutation<
  ProjectAddUsedBlockLanguageMutation,
  ProjectAddUsedBlockLanguageMutationVariables
> {
  document = ProjectAddUsedBlockLanguageDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ProjectChangeMemberRoleDocument = gql`
  mutation ProjectChangeMemberRole(
    $projectId: ID!
    $userId: ID!
    $isAdmin: Boolean!
  ) {
    changeMemberRole(
      input: { projectId: $projectId, userId: $userId, isAdmin: $isAdmin }
    ) {
      project {
        id
        projectMembers {
          id
          createdAt
          membershipType
          user {
            id
            displayName
          }
        }
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class ProjectChangeMemberRoleGQL extends Apollo.Mutation<
  ProjectChangeMemberRoleMutation,
  ProjectChangeMemberRoleMutationVariables
> {
  document = ProjectChangeMemberRoleDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ProjectChangeOwnerDocument = gql`
  mutation ProjectChangeOwner($projectId: ID!, $userId: ID!) {
    changeOwner(input: { projectId: $projectId, userId: $userId }) {
      project {
        id
        projectMembers {
          createdAt
          membershipType
          joinedAt
          id
          user {
            id
            displayName
          }
        }
        user {
          id
          displayName
        }
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class ProjectChangeOwnerGQL extends Apollo.Mutation<
  ProjectChangeOwnerMutation,
  ProjectChangeOwnerMutationVariables
> {
  document = ProjectChangeOwnerDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ProjectRemoveMemberDocument = gql`
  mutation ProjectRemoveMember($projectId: ID!, $userId: ID!) {
    removeMember(input: { projectId: $projectId, userId: $userId }) {
      project {
        id
        projectMembers {
          createdAt
          id
          user {
            id
            displayName
          }
        }
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class ProjectRemoveMemberGQL extends Apollo.Mutation<
  ProjectRemoveMemberMutation,
  ProjectRemoveMemberMutationVariables
> {
  document = ProjectRemoveMemberDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ProjectRemoveUsedBlockLanguageDocument = gql`
  mutation ProjectRemoveUsedBlockLanguage($usedBlockLanguageId: ID!) {
    removeUsedBlockLanguage(
      input: { usedBlockLanguageId: $usedBlockLanguageId }
    ) {
      errors
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class ProjectRemoveUsedBlockLanguageGQL extends Apollo.Mutation<
  ProjectRemoveUsedBlockLanguageMutation,
  ProjectRemoveUsedBlockLanguageMutationVariables
> {
  document = ProjectRemoveUsedBlockLanguageDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const PromoteUserAdminDocument = gql`
  mutation PromoteUserAdmin($userId: ID!) {
    promoteUserAdmin(input: { userId: $userId }) {
      user {
        id
        roles {
          id
          name
          updatedAt
          createdAt
        }
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class PromoteUserAdminGQL extends Apollo.Mutation<
  PromoteUserAdminMutation,
  PromoteUserAdminMutationVariables
> {
  document = PromoteUserAdminDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RegenerateForeignTypesDocument = gql`
  mutation RegenerateForeignTypes($id: ID!) {
    regenerateForeignTypes(input: { id: $id }) {
      grammar {
        foreignTypes
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class RegenerateForeignTypesGQL extends Apollo.Mutation<
  RegenerateForeignTypesMutation,
  RegenerateForeignTypesMutationVariables
> {
  document = RegenerateForeignTypesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SelectionListBlockLanguagesDocument = gql`
  query SelectionListBlockLanguages {
    blockLanguages {
      nodes {
        id
        name
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class SelectionListBlockLanguagesGQL extends Apollo.Query<
  SelectionListBlockLanguagesQuery,
  SelectionListBlockLanguagesQueryVariables
> {
  document = SelectionListBlockLanguagesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SelectionListGrammarsDocument = gql`
  query SelectionListGrammars {
    grammars {
      nodes {
        id
        name
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class SelectionListGrammarsGQL extends Apollo.Query<
  SelectionListGrammarsQuery,
  SelectionListGrammarsQueryVariables
> {
  document = SelectionListGrammarsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SelectionListGrammarsCreateBlockLanguageDocument = gql`
  query SelectionListGrammarsCreateBlockLanguage {
    grammars {
      nodes {
        id
        name
        programmingLanguageId
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class SelectionListGrammarsCreateBlockLanguageGQL extends Apollo.Query<
  SelectionListGrammarsCreateBlockLanguageQuery,
  SelectionListGrammarsCreateBlockLanguageQueryVariables
> {
  document = SelectionListGrammarsCreateBlockLanguageDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const StoreBlockLanguageSeedDocument = gql`
  mutation StoreBlockLanguageSeed($blockLanguageIds: [String!]!) {
    storeBlockLanguageSeed(input: { blockLanguages: $blockLanguageIds }) {
      affectedIds {
        id
        type
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class StoreBlockLanguageSeedGQL extends Apollo.Mutation<
  StoreBlockLanguageSeedMutation,
  StoreBlockLanguageSeedMutationVariables
> {
  document = StoreBlockLanguageSeedDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const StoreProjectSeedDocument = gql`
  mutation StoreProjectSeed($projectIds: [String!]!) {
    storeProjectSeed(input: { projects: $projectIds }) {
      affectedIds {
        id
        type
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class StoreProjectSeedGQL extends Apollo.Mutation<
  StoreProjectSeedMutation,
  StoreProjectSeedMutationVariables
> {
  document = StoreProjectSeedDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateBlockLanguageDocument = gql`
  mutation UpdateBlockLanguage(
    $id: ID
    $name: String
    $slug: String
    $defaultProgrammingLanguageId: ID
    $grammarId: ID
    $sidebars: JSON
    $editorBlocks: JSON
    $rootCssClasses: JSON
    $editorComponents: JSON
    $localGeneratorInstructions: JSON
  ) {
    updateBlockLanguage(
      input: {
        id: $id
        name: $name
        slug: $slug
        defaultProgrammingLanguageId: $defaultProgrammingLanguageId
        grammarId: $grammarId
        sidebars: $sidebars
        editorBlocks: $editorBlocks
        rootCssClasses: $rootCssClasses
        editorComponents: $editorComponents
        localGeneratorInstructions: $localGeneratorInstructions
      }
    ) {
      id
      errors
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class UpdateBlockLanguageGQL extends Apollo.Mutation<
  UpdateBlockLanguageMutation,
  UpdateBlockLanguageMutationVariables
> {
  document = UpdateBlockLanguageDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateCodeResourceDocument = gql`
  mutation UpdateCodeResource(
    $id: ID!
    $name: String!
    $ast: NodeDescription
    $blockLanguageId: ID!
    $programmingLanguageId: ID!
  ) {
    updateCodeResource(
      input: {
        id: $id
        name: $name
        ast: $ast
        blockLanguageId: $blockLanguageId
        programmingLanguageId: $programmingLanguageId
      }
    ) {
      codeResource {
        name
        ast
        blockLanguageId
        programmingLanguageId
      }
      affected {
        ... on BlockLanguage {
          id
          name
          sidebars
          editorBlocks
          editorComponents
          localGeneratorInstructions
          rootCssClasses
          grammarId
          defaultProgrammingLanguageId
          __typename
        }
        ... on Grammar {
          id
          name
          root
          types
          foreignTypes
          visualisations
          foreignVisualisations
          includes
          visualizes
          programmingLanguageId
          __typename
        }
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class UpdateCodeResourceGQL extends Apollo.Mutation<
  UpdateCodeResourceMutation,
  UpdateCodeResourceMutationVariables
> {
  document = UpdateCodeResourceDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateGrammarDocument = gql`
  mutation UpdateGrammar(
    $id: ID!
    $name: String
    $slug: String
    $types: JSON
    $foreignTypes: JSON
    $root: QualifiedTypeName
    $programmingLanguageId: ID
    $generatedFromId: ID
  ) {
    updateGrammar(
      input: {
        id: $id
        name: $name
        slug: $slug
        types: $types
        foreignTypes: $foreignTypes
        root: $root
        programmingLanguageId: $programmingLanguageId
        generatedFromId: $generatedFromId
      }
    ) {
      grammar {
        id
        name
        programmingLanguageId
        slug
        generatedFromId
        foreignTypes
        root
        types
        includes
        visualizes
      }
      errors
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class UpdateGrammarGQL extends Apollo.Mutation<
  UpdateGrammarMutation,
  UpdateGrammarMutationVariables
> {
  document = UpdateGrammarDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateNewsDocument = gql`
  mutation UpdateNews(
    $id: ID!
    $title: LangJson!
    $text: LangJson!
    $publishedFrom: SettableDate!
  ) {
    updateNews(
      input: {
        id: $id
        title: $title
        text: $text
        publishedFrom: $publishedFrom
      }
    ) {
      news {
        id
        createdAt
        updatedAt
        publishedFrom
        text
        title
        userId
      }
      errors
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class UpdateNewsGQL extends Apollo.Mutation<
  UpdateNewsMutation,
  UpdateNewsMutationVariables
> {
  document = UpdateNewsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateProjectDocument = gql`
  mutation UpdateProject(
    $id: ID!
    $name: LangJson
    $description: LangJson
    $preview: ID
  ) {
    updateProject(
      input: {
        id: $id
        name: $name
        description: $description
        preview: $preview
      }
    ) {
      errors
      project {
        name
        description
        slug
        preview
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class UpdateProjectGQL extends Apollo.Mutation<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
> {
  document = UpdateProjectDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
