<div class="card h-100 project-list-item">
  <img
    *ngIf="hasImage"
    class="card-img-top img-fluid"
    [src]="imageUrl"
    alt="Preview Image"
  />
  <div class="card-img-overlay">
    <h2 class="card-title">{{ project.name | currentLanguage }}</h2>
  </div>
  <div class="card-body">
    <h2 *ngIf="!hasImage" class="card-title">
      {{ project.name | currentLanguage }}
    </h2>
    <ul class="list-inline mb-1">
      <li
        *ngFor="let blockLang of project.blockLanguages"
        class="list-inline-item"
      >
        <span class="badge rounded-pill bg-secondary">
          {{blockLang.defaultProgrammingLanguage.name}}
        </span>
      </li>
    </ul>
    <p class="card-text">{{ project.description | currentLanguage }}</p>
  </div>
  <div class="card-footer">
    <a
      [routerLink]="['/editor/', project | urlFriendlyId]"
      class="btn btn-primary btn-block btn-edit-project"
    >
      <span class="fa fa-fw fa-pencil" aria-hidden="true"></span>
      <span i18n="@@edit">Bearbeiten</span>
    </a>
  </div>
</div>
